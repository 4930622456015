import React, { useEffect, useState } from "react";
import { Button } from "../../../ui";
import { AddIcon } from "../../../../icons";
import { useThunk } from "../../../../hooks/useThunk";
import {
  fetchAdvancedDegrees,
  fetchCertificationPrograms,
  fetchCommitteeInvolvements,
  fetchConferenceAttendances,
  fetchPanelDiscussions,
  fetchProfessionalOrganizations,
  fetchPublicSpeakings,
  fetchUsersHighFive,
  fetchWorkshopSeminars,
} from "../../../../store";
import { useSelector } from "react-redux";
import ModalShell from "./Modals/ModalShell";
import { conferenceModalViews } from "../../../../data/views";
import ActivityItem from "./HelperComponents/ActivityItem";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetConferenceAttendanceMessage } from "../../../../store/conferenceAttendance/conferenceAttendanceSlice";
import { resetWorkshopSeminarMessage } from "../../../../store/workshopSeminar/workshopSeminarSlice";
import { resetCommitteeInvolvementMessage } from "../../../../store/committeeInvolvement/committeeInvolvementSlice";
import { resetProfessionalOrganizationMessage } from "../../../../store/professionalOrganization/professionalOrganizationSlice";
import { resetPanelDiscussionMessage } from "../../../../store/panelDiscussion/panelDiscussionSlice";
import { resetPublicSpeakingMessage } from "../../../../store/publicSpeaking/publicSpeakingSlice";
import { resetAdvancedDegreeMessage } from "../../../../store/advancedDegree/advancedDegreeSlice";
import { resetCertificationProgramMessage } from "../../../../store/certificationProgram/certificationProgramSlice";

const ConferenceReview = ({ userId }) => {
  const messageStateConference = useSelector(
    (state) => state.conferenceAttendance
  )?.message;
  const messageWorkshopSeminars = useSelector(
    (state) => state.workshopSeminar
  )?.message;
  const messagePublicSpeakings = useSelector(
    (state) => state.publicSpeaking
  )?.message;
  const messagePanelDiscussions = useSelector(
    (state) => state.panelDiscussion
  )?.message;
  const messageProfessionalOrganizations = useSelector(
    (state) => state.professionalOrganization
  )?.message;
  const messageCommitteeInvolvements = useSelector(
    (state) => state.committeeInvolvement
  )?.message;
  const messageAdvancedDegrees = useSelector(
    (state) => state.advancedDegree
  )?.message;
  const messageCertificationPrograms = useSelector(
    (state) => state.certificationProgram
  )?.message;

  const [dohShowConferenceMessage] = useToastMessage(
    resetConferenceAttendanceMessage
  );
  const [doShowWorkshopSeminarsMessage] = useToastMessage(
    resetWorkshopSeminarMessage
  );
  const [doShowPublicSpeakingsMessage] = useToastMessage(
    resetPublicSpeakingMessage
  );
  const [doShowPanelDiscussionsMessage] = useToastMessage(
    resetPanelDiscussionMessage
  );
  const [doShowProfessionalOrganizationsMessage] = useToastMessage(
    resetProfessionalOrganizationMessage
  );
  const [doShowCommitteeInvolvementsMessage] = useToastMessage(
    resetCommitteeInvolvementMessage
  );
  const [doShowAdvancedDegreesMessage] = useToastMessage(
    resetAdvancedDegreeMessage
  );
  const [doShowCertificationProgramsMessage] = useToastMessage(
    resetCertificationProgramMessage
  );

  const [doFetchHighFives] = useThunk(fetchUsersHighFive);
  const [doFetchWorkshopSeminars] = useThunk(fetchWorkshopSeminars);
  const [doFetchConferenceAttendance] = useThunk(fetchConferenceAttendances);
  const [doFetchPublicSpeaking] = useThunk(fetchPublicSpeakings);
  const [doFetchPanelDiscussions] = useThunk(fetchPanelDiscussions);
  const [doFetchProfessionalOrganizations] = useThunk(
    fetchProfessionalOrganizations
  );
  const [doFetchCommitteeInvolvement] = useThunk(fetchCommitteeInvolvements);
  const [doFetchAdvancedDegrees] = useThunk(fetchAdvancedDegrees);
  const [doFetchCertificationPrograms] = useThunk(fetchCertificationPrograms);

  const workshopSeminars = useSelector((state) => state?.workshopSeminar?.data);
  const conferenceAttendances = useSelector(
    (state) => state?.conferenceAttendance?.data
  );
  const publicSpeakings = useSelector((state) => state?.publicSpeaking?.data);
  const panelDiscussions = useSelector((state) => state?.panelDiscussion?.data);
  const professionalOrganizations = useSelector(
    (state) => state?.professionalOrganization?.data
  );
  const committeeInvolvements = useSelector(
    (state) => state?.committeeInvolvement?.data
  );
  const advancedDegrees = useSelector((state) => state?.advancedDegree?.data);
  const certificationPrograms = useSelector(
    (state) => state?.certificationProgram?.data
  );

  const [isModalShown, setIsModalShown] = useState(false);

  const activities = {
    workshopSeminars,
    conferenceAttendances,
    publicSpeakings,
    panelDiscussions,
    professionalOrganizations,
    committeeInvolvements,
    advancedDegrees,
    certificationPrograms,
  };

  const highFivesState = useSelector(
    (state) => state?.highFives?.usersHighFives
  )?.highFives;

  useEffect(() => {
    doFetchHighFives({ userId });
    doFetchWorkshopSeminars({ user_id: userId });
    doFetchConferenceAttendance({ user_id: userId });
    doFetchPublicSpeaking({ user_id: userId });
    doFetchPanelDiscussions({ user_id: userId });
    doFetchProfessionalOrganizations({ user_id: userId });
    doFetchCommitteeInvolvement({ user_id: userId });
    doFetchAdvancedDegrees({ user_id: userId });
    doFetchCertificationPrograms({ user_id: userId });
  }, []);

  useEffect(() => {
    dohShowConferenceMessage(messageStateConference);
    doShowWorkshopSeminarsMessage(messageWorkshopSeminars);
    doShowPublicSpeakingsMessage(messagePublicSpeakings);
    doShowPanelDiscussionsMessage(messagePanelDiscussions);
    doShowProfessionalOrganizationsMessage(messageProfessionalOrganizations);
    doShowCommitteeInvolvementsMessage(messageCommitteeInvolvements);
    doShowAdvancedDegreesMessage(messageAdvancedDegrees);
    doShowCertificationProgramsMessage(messageCertificationPrograms);
  }, [
    messageStateConference,
    messageWorkshopSeminars,
    messagePublicSpeakings,
    messagePanelDiscussions,
    messageProfessionalOrganizations,
    messageCommitteeInvolvements,
    messageAdvancedDegrees,
    messageCertificationPrograms,
  ]);

  const RenderActivities = ({ activity, stateActivity }) => {
    return (
      <div className="activity">
        <div className="title-line">
          <h4 className="title">
            {
              conferenceModalViews?.find((view) => view?.dataKey === activity)
                ?.label
            }
          </h4>
        </div>
        <div className="activity-group">
          {stateActivity?.map((stateData, index) => (
            <ActivityItem
              key={index}
              activity={activity}
              activityItem={stateData}
              setIsModalShown={setIsModalShown}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="conference">
      <div className="conferences-lists">
        <div className="title-line">
          <h3 className="title">Activities</h3>
          <Button
            variant="control"
            buttonStyles={{ cursor: "pointer" }}
            handleClick={() => setIsModalShown(true)}
          >
            Add new
            <AddIcon />
          </Button>
        </div>
        {conferenceModalViews?.map((view) => (
          <div key={`conference-modal-view-${view.dataKey}`}>
            <RenderActivities
              activity={view?.dataKey}
              stateActivity={activities[view?.dataKey]}
            />
          </div>
        ))}
      </div>
      <div className="high-fives">
        <div className="title-line">
          <h3 className="title">{`High Fives: ${highFivesState?.length}`}</h3>
        </div>
        <div className="high-fives-list">
          <ul className="high-fives-items">
            {highFivesState?.map((highFive, index) => (
              <li key={`highFive-${index}`}>
                <p>
                  <span className="acter">{highFive?.sender?.name}</span>
                  {" to "}
                  <span className="acter">{highFive?.receiver?.name}</span>
                </p>
                <p>{highFive?.message}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ModalShell
        isShown={isModalShown}
        handleModalClose={() => setIsModalShown(false)}
      />
    </div>
  );
};

export default ConferenceReview;
