export const supportCaseTypes = [
  { value: 1, label: "Part time" },
  { value: 2, label: "2 Hours a day" },
  { value: 3, label: "10% of time" },
];

export const seniorityTypes = [
  { value: 1, label: "Junior" },
  { value: 2, label: "Medior" },
  { value: 3, label: "Senior" },
];

export const projectNotificationTypes = {
  levels: [
    { value: 1, label: "Task" },
    { value: 2, label: "Project" },
  ],
  times: [
    { value: 1, label: "After 1 day of the last update" },
    { value: 2, label: "After 3 days of the last update" },
    { value: 3, label: "Weekly" },
  ],
  types: [
    { value: 1, label: "Email + App notification" },
    { value: 2, label: "App notification" },
    { value: 3, label: "Turn off the notifications" },
  ],
};

export const checkinsTypes = [
  {
    imageName: "not-well",
    value: 1,
  },
  {
    imageName: "not-ok",
    value: 2,
  },
  {
    imageName: "ok",
    value: 3,
  },
  {
    imageName: "good",
    value: 4,
  },
  {
    imageName: "amazing",
    value: 5,
  },
];

export const checkinsFilterColors = [
  "#D9B8FF",
  "#FFD966",
  "#85E0A3",
  "#80CAFF",
  "#E27D7D",
  "#EF7DD6",
  "#622AB2",
  "#BE8628",
  "#26B489",
  "#2F5AB6",
  "#BE3939",
  "#CA3CAB",
  "#A2845E",
  "#884848",
  "#578848",
  "#487588",
  "#884884",
  "#818600",
];

export const performanceReviewExpectationOptions = [
  { value: 1, label: "Not Met", color: "#f07171" },
  { value: 2, label: "Met", color: "#a9ce5b" },
  { value: 3, label: "Exceeded", color: "#14b4ba" },
];
