export const dummySuggestion = {
  wentWell: [
    { id: 1, date: "01.01.2024", detail: "The team collaborated effectively." },
    {
      id: 2,
      date: "02.01.2024",
      detail: "Project milestones were met on time.",
    },
    {
      id: 3,
      date: "03.01.2024",
      detail: "Communication between team members was clear.",
    },
  ],
  issues: [
    { id: 1, date: "01.01.2024", issue: "Lack of proper documentation." },
    {
      id: 2,
      date: "02.01.2024",
      issue: "Insufficient testing before deployment.",
    },
    { id: 3, date: "03.01.2024", issue: "Delayed feedback from stakeholders." },
  ],
  solutions: [
    {
      id: 1,
      date: "04.01.2024",
      suggestion:
        "Create a documentation template to be used by all team members.",
      issued: 1,
    },
    {
      id: 2,
      date: "05.01.2024",
      suggestion:
        "Implement a more rigorous testing phase with automated tests.",
      issued: 2,
    },
    {
      id: 3,
      date: "06.01.2024",
      suggestion:
        "Schedule regular check-ins with stakeholders to ensure timely feedback.",
      issued: 3,
    },
  ],
};

export const lineChartDummyData = [
  3,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  4,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  5,
  NaN,
  NaN,
  NaN,
  NaN,
  3,
  NaN,
  NaN,
  NaN,
  NaN,
  4,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  5,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  4,
];

export const dummyTeamsBarChartData = [
  {
    teamId: 1,
    teamName: "Ugofy",
    grades: {
      not_well: 1,
      not_ok: 0,
      ok: 5,
      good: 2,
      amazing: 10,
    },
  },
  {
    teamId: 2,
    teamName: "Frontend",
    grades: {
      not_well: 1,
      not_ok: 1,
      ok: 1,
      good: 4,
      amazing: 3,
    },
  },
  {
    teamId: 3,
    teamName: "Backend",
    grades: {
      not_well: 1,
      not_ok: 2,
      ok: 4,
      good: 1,
      amazing: 2,
    },
  },
  {
    teamId: 4,
    teamName: "Middlend",
    grades: {
      not_well: 1,
      not_ok: 0,
      ok: 2,
      good: 5,
      amazing: 1,
    },
  },
];
