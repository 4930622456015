import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Button, CardFilter } from "../../../ui";
import {
  CircleCheckIcon,
  ClockIcon,
  SingleArrow,
  TIcon,
  ThreeDotsHorizontalIcon,
} from "../../../../icons";
import "./styles.scss";
import { dummuMeetings } from "./dummyData";

const MeetingsReview = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const meetings = dummuMeetings;
  const doughnutData = {
    labels: [],
    datasets: [
      {
        label: "Number of meetings",
        data: [5, 4, 3],
        backgroundColor: ["#A9CE5B", "#F07171", "#14B4BA"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <div className="meetings-review">
      <div className="meetings">
        <div className="one-on-one">
          <div className="title-line">
            <h2 className="title">1-1 Meetings</h2>
            {/* TODO: Dodati onClick funkciju kada se resi 1-1 */}
            <Button variant="control">
              Show all
              <SingleArrow />
            </Button>
          </div>
          <ul className="one-on-one-list">
            {meetings
              .filter((meeting) => meeting?.meetingType === 1)
              .map((meeting, index) => (
                <li key={`meet-${index}`}>
                  <div className="column">
                    <p className="name">{meeting?.meetWidth?.name}</p>
                    <p>{meeting?.meetWidth?.position}</p>
                  </div>
                  <p className="column">
                    {meeting?.type === 1 ? "Online" : "In person"}
                  </p>
                  <div className="column">
                    {!!meeting.scheduled ? (
                      meeting.scheduled
                    ) : (
                      <div className="not-scheduled">Not scheduled</div>
                    )}
                  </div>
                  <div className="column">
                    {/* TODO: Dodati onClick funkciju kada se resi 1-1 */}
                    <Button variant="action">
                      <ThreeDotsHorizontalIcon />
                    </Button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="meetings-attendance">
          <div className="title-line">
            <h2 className="title">Meeting attendance</h2>
          </div>
          <div className="cards">
            <CardFilter
              borderColor="#109095"
              backgroundColor="#14B4BA"
              icon={<TIcon />}
              count={meetings?.length}
              title="Total Meetings"
            />
            <CardFilter
              borderColor="#86A347"
              backgroundColor="#A9CE5B"
              icon={<CircleCheckIcon />}
              count={meetings?.length}
              title="Attended Meetings"
            />
            <CardFilter
              borderColor="#B35656"
              backgroundColor="#F07171"
              icon={<ClockIcon />}
              count={meetings?.length}
              title="Unattended Meetings"
            />
            <CardFilter
              borderColor="#86A347"
              backgroundColor="#A9CE5B"
              icon={<CircleCheckIcon />}
              count={meetings?.length}
              title="1-1 Meetings"
            />
          </div>
        </div>
      </div>
      <div className="chart">
        <div className="doughnut-wrapper">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
      </div>
    </div>
  );
};

export default MeetingsReview;
