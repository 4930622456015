import { createSlice } from "@reduxjs/toolkit";
import {
  createAnswers,
  createMood,
  fetchQuestions,
  fetchUserCheckinsHistory,
  fetchUserMoodStatistics,
} from "./checkinsThunk";

const checkinsSlice = createSlice({
  name: "checkins",
  initialState: {
    data: [],
    questions: [],
    mood: null,
    // history: [],
    userHistory: [],
    userMoodStatistics: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(createMood.fulfilled, (state, action) => {
      state.mood = action.payload;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
    // builder.addCase(fetchCheckinsHistory.fulfilled, (state, action) => {
    //   state.history = action.payload;
    // });
    builder.addCase(fetchUserCheckinsHistory.fulfilled, (state, action) => {
      state.userHistory = action.payload;
    });
    builder.addCase(fetchUserMoodStatistics.fulfilled, (state, action) => {
      state.userMoodStatistics = action.payload;
    });
  },
});

export const checkinsReducer = checkinsSlice.reducer;
