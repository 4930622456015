import React, { useEffect, useState } from "react";
import { Button, TypeAhead } from "../../../ui";
import "./styles.scss";
import { useThunk } from "../../../../hooks/useThunk";
import {
  createAnswers,
  createMood,
  fetchTeams,
  fetchUserCheckinsHistory,
  fetchUsers,
  fetchUserMoodStatistics,
} from "../../../../store";
import moment from "moment";
import { useSelector } from "react-redux";
import { dummyTeamsBarChartData, lineChartDummyData } from "./dummyData";
import { checkinsFilterColors, checkinsTypes } from "../../../../data/types";
import SuggestionColumn from "./helperComponents/SuggestionColumn";
import LineChartCheckins from "./helperComponents/LineChartCheckins";
import DoughnutChartCheckins from "./helperComponents/DoughnutChartCheckins";
import { AddIcon, SearchIcon, SubtractIcon } from "../../../../icons";
import CheckinsHistory from "./helperComponents/CheckinsHistory";

const CheckIns = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchHistory] = useThunk(fetchUserCheckinsHistory);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doCreateMood] = useThunk(createMood);
  const [doCreateAnswer] = useThunk(createAnswers);
  const [doFetchUserMoodStatistics] = useThunk(fetchUserMoodStatistics);

  const usersState = useSelector((state) => state?.users?.data)?.userData;
  const checkinsHistoryState = useSelector(
    (state) => state?.checkins
  )?.userHistory;
  const teamsState = useSelector((state) => state?.teams?.teams)?.teamData;
  const usersMoodStatisticsState = useSelector(
    (state) => state?.checkins
  )?.userMoodStatistics;

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCheckin, setSelectedCheckin] = useState(null);
  const [mood, setMood] = useState({ user_id: localUserData?.id, grade: 0 });
  const [suggestions, setSuggestions] = useState({
    wentWell: [],
    issues: [],
    solutions: [],
  });
  const [suggestionValue, setSuggestionValue] = useState({
    wentWell: "",
    issues: "",
    solutions: "",
  });
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [barChartSelectedMood, setBarChartSelectedMood] = useState(null);
  const [barChartData, setBarChartData] = useState({
    labels: [],
    data: [],
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [addSolution, setAddSolution] = useState(false);
  const [referenceCounter, setReferenceCounter] = useState(0);
  const [redBorderMark, setRedBorderMark] = useState(false);

  const today = moment(new Date()).format("DD.MM.YY");
  const lineChartDatasets = [
    {
      data: lineChartDummyData,
      fill: false,
      borderColor: checkinsFilterColors[0],
      backgroundColor: checkinsFilterColors[0],
      pointBackgroundColor: "#fff",
      pointBorderColor: checkinsFilterColors[0],
      pointRadius: 5,
      segment: {
        borderColor: (ctx) => skipped(ctx, checkinsFilterColors[0]),
      },
      spanGaps: true,
      tension: 0.3,
    },
    {
      data: [...lineChartDummyData].reverse(),
      fill: false,
      borderColor: checkinsFilterColors[1],
      backgroundColor: checkinsFilterColors[1],
      pointBackgroundColor: "#fff",
      pointBorderColor: checkinsFilterColors[1],
      pointRadius: 5,
      segment: {
        borderColor: (ctx) => skipped(ctx, checkinsFilterColors[1]),
      },
      spanGaps: true,
      tension: 0.3,
    },
  ];
  const lineChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        type: "category",
        labels: [5, 4, 3, 2, 1],
        afterFit: (scaleInsance) => {
          scaleInsance.width = 37;
        },
        grid: {
          display: true,
          color: "rgba(228, 227, 227, 0.8)",
        },
      },
      y1: {
        type: "category",
        position: "right",
        labels: ["Amazing!", "Not well"],
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          color: "rgba(228, 227, 227, 0.3)",
        },
      },
    },
  };

  const doughnutChartLabels = [];

  const doughnutChartDatasets = [
    {
      label: "My data",
      data: [4, 12, 12, 12, 16],
      backgroundColor: checkinsFilterColors,
      borderColor: ["#fff"],
      color: "#fff",
      hoverOffset: 10,
    },
  ];

  const doughnutChartOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
      },
      legend: {
        labels: {
          display: false,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const dateFilterOptions = ["Years", "Months in a year", "Weeks in Month"];

  useEffect(() => {
    doFetchHistory(localUserData?.id);
    doFetchTeams({});
    doFetchUserMoodStatistics({ user: localUserData.id, range: "week" });
    calculateCheckins();
  }, []);

  useEffect(() => {
    if (!!barChartSelectedMood) {
      const data = [];
      dummyTeamsBarChartData.map((team) => {
        const grade = checkinsTypes
          .find((type) => type.value === barChartSelectedMood)
          .imageName.replace("-", "_");
        data.push(team?.grades?.[grade]);
      });
      setBarChartData({
        labels: dummyTeamsBarChartData.map((team) => team?.teamName),
        data: data,
      });
    } else {
      setBarChartData({ labels: [], data: [] });
    }
  }, [barChartSelectedMood]);

  useEffect(() => {
    if (checkinsHistoryState?.length > 0) {
      for (const historyItem of checkinsHistoryState) {
        if (historyItem?.date === today) {
          setSelectedCheckin(historyItem);
          break;
        }
      }
    }
  }, [checkinsHistoryState]);

  useEffect(() => {
    if (!!selectedCheckin) {
      let maxReference = 0;

      if (selectedCheckin.date === today) {
        setIsSaveDisabled(false);
        setIsEditDisabled(false);
      } else {
        setIsSaveDisabled(true);
        setIsEditDisabled(true);
      }

      selectedCheckin?.questions
        ?.filter((question) => question?.question_id === 2)
        .map((question) => {
          if (question?.reference > maxReference) {
            maxReference = question?.reference;
          }
        });
      setReferenceCounter(maxReference);
    } else {
      setIsSaveDisabled(false);
    }
  }, [selectedCheckin]);

  useEffect(() => {
    const wentWell = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 1)
      .map((question) => {
        return { answer: question?.answer_text, id: question?.answer_id };
      });
    const issues = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 2)
      .map((question) => {
        return {
          answer: question?.answer_text,
          id: question?.answer_id,
          reference: question?.reference,
        };
      });
    const solutions = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 3)
      .map((question) => {
        return {
          answer: question?.answer_text,
          id: question?.answer_id,
          refered_to: question?.refered_to,
        };
      });
    setSuggestions({ wentWell, issues, solutions });
  }, [selectedCheckin]);

  useEffect(() => {
    if (redBorderMark) {
      setTimeout(() => {
        setRedBorderMark(false);
      }, 3000);
    }
  }, [redBorderMark]);

  const handleUserSearch = (searchTerm) => {
    doFetchUsers({ searchTerm });
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleHistoryClick = () => {
    setShowHistory(true);
  };

  const handleHistoryClose = () => {
    setShowHistory(false);
  };

  const handleMoodClick = (value) => {
    setMood({ ...mood, grade: value });
  };

  const handleSuggestionChange = (property, value) => {
    setSuggestionValue({ ...suggestionValue, [property]: value });
  };

  const handleSuggestionAdd = (property) => {
    console.log(property);
    let propertyArray = [];
    switch (property) {
      case "wentWell":
        propertyArray = [
          {
            question: 1,
            answer: suggestionValue[property],
            user_id: localUserData.id,
            reference: null,
            refered_to: null,
          },
          ...(suggestions[property] || []),
        ];
        setSuggestionValue({ ...suggestionValue, wentWell: "" });
        break;
      case "issues":
        setReferenceCounter((prev) => {
          const counter = prev + 1;
          propertyArray = [
            {
              question: 2,
              answer: suggestionValue[property],
              user_id: localUserData.id,
              reference: counter,
              refered_to: null,
            },
            ...(suggestions[property] || []),
          ];
          return counter;
        });
        setAddSolution(true);
        break;
      case "solutions":
        propertyArray = [
          {
            question: 3,
            answer: suggestionValue[property],
            user_id: localUserData.id,
            refered_to: referenceCounter,
            reference: null,
          },
          ...(suggestions[property] || []),
        ];
        setAddSolution(false);
        setSuggestionValue({ ...suggestionValue, solution: "" });
        break;
      default:
        break;
    }

    setSuggestions({ ...suggestions, [property]: propertyArray });
    setSuggestionValue({ ...suggestionValue, [property]: "" });
  };

  const handleSuggestionAddCancel = (property) => {
    setSuggestionValue({ suggestionValue, [property]: "" });
  };

  const handleSuggestionEdit = (selectedIndex, property) => {
    setSuggestions({
      ...suggestions,
      [property]: suggestions?.[property].map((suggestion, index) => {
        if (index === selectedIndex) {
          return {
            ...suggestion,
            answer: suggestionValue?.[property],
          };
        }
        return suggestion;
      }),
    });
  };

  const handleSuggestionDelete = (selectedIndex, property) => {
    const reference = suggestions?.[property][selectedIndex]?.reference;
    if (!!reference) {
      setSuggestions({
        ...suggestions,
        [property]: suggestions?.[property]?.filter(
          (suggestion, index) => index !== selectedIndex
        ),
        solutions: suggestions?.solutions?.filter(
          (solution) => solution?.refered_to !== reference
        ),
      });
      return;
    }
    setSuggestions({
      ...suggestions,
      [property]: suggestions?.[property]?.filter(
        (suggestion, index) => index !== selectedIndex
      ),
    });
  };

  const handleChartFilterChange = (value) => {
    console.log({ value });
  };

  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;

  const calculateCheckins = () => {
    let filteredCheckins = lineChartDummyData.filter((data) => !!data);
    let chartData = [];
    chartData.push(filteredCheckins.filter((data) => data === 1).length);
    chartData.push(filteredCheckins.filter((data) => data === 2).length);
    chartData.push(filteredCheckins.filter((data) => data === 3).length);
    chartData.push(filteredCheckins.filter((data) => data === 4).length);
    chartData.push(filteredCheckins.filter((data) => data === 5).length);

    doughnutChartDatasets[0].data = chartData;
  };

  const handleTeamSearch = (searchTerm) => {
    doFetchTeams({ searchTerm: searchTerm });
  };

  const handleTypeAheadSelect = (teams) => {
    setSelectedTeams(teams);
  };

  const handleRemoveTeam = (id) => {
    setSelectedTeams(selectedTeams.filter((team) => team?.id !== id));
  };

  const handleDoughnutHover = (index) => {
    if (!!index) {
      setBarChartSelectedMood(index);
    } else {
      setBarChartSelectedMood(null);
    }
  };

  const handleSaveClick = async () => {
    if (!selectedCheckin && mood?.grade === 0) {
      setRedBorderMark(true);
      return;
    }
    if (!selectedCheckin && mood?.grade !== 0) {
      await doCreateMood(mood);
    }

    const createAnswerPromises = [];
    for (const key of Object.keys(suggestions)) {
      const answers = suggestions[key];
      if (answers) {
        for (const answer of answers) {
          if (!answer?.id) {
            createAnswerPromises.push(doCreateAnswer(answer));
          }
        }
      }
    }

    await Promise.all(createAnswerPromises);

    await doFetchHistory(localUserData?.id);
  };

  const handleHistoryItemClick = (index) => {
    setSelectedCheckin(checkinsHistoryState[index]);
  };

  const handleHistoryCurrentClick = () => {
    setSelectedCheckin(null);
    handleHistoryClose();
  };

  return (
    <div className="checkins-wrapper">
      <div className="title-wrapper">
        <h2>{`My check-in: ${selectedCheckin?.date || today}`}</h2>
        <div className="flex gap-20 items-center">
          <TypeAhead
            placeholder="Search user"
            icon={<SearchIcon />}
            options={usersState || []}
            slug={(option) => `${option?.first_name} ${option?.last_name}`}
            handleSearch={handleUserSearch}
            setSelected={handleUserSelect}
            selected={selectedUser}
          />
          <Button handleClick={handleSaveClick} disabled={isSaveDisabled}>
            Save
          </Button>
          <Button variant="secondary" handleClick={handleHistoryClick}>
            History
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="checkins">
          <div className="mood">
            <h3>How did you feel at work since last Check-in</h3>
            <div className={`mood-list ${redBorderMark ? "red-border" : ""}`}>
              <span>Not well</span>
              {checkinsTypes.map((moodItem, index) => (
                <Button
                  key={`mood-${index}`}
                  variant="action"
                  disabled={!!selectedCheckin}
                  className={`mood-btn ${
                    moodItem?.value === selectedCheckin?.grades[0].grade ||
                    moodItem?.value === mood?.grade
                      ? "checked"
                      : ""
                  }`}
                  handleClick={() => handleMoodClick(moodItem?.value)}
                >
                  <img
                    src={require(`./images/${moodItem?.imageName}.png`)}
                    alt={moodItem?.imageName}
                  />
                </Button>
              ))}
              <span>Amazing!</span>
            </div>
          </div>
          <div className="suggestions">
            <SuggestionColumn
              title="What went well"
              dataItems={suggestions?.wentWell}
              color="green"
              addInputValue={suggestionValue?.wentWell}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("wentWell", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("wentWell")}
              onAddInputCancel={() => handleSuggestionAddCancel("wentWell")}
              onItemEdit={(index) => handleSuggestionEdit(index, "wentWell")}
              onItemDelete={(index) =>
                handleSuggestionDelete(index, "wentWell")
              }
            />
            <SuggestionColumn
              title="What you didnt like"
              dataItems={suggestions?.issues}
              color="red"
              addInputValue={suggestionValue?.issues}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("issues", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("issues")}
              onAddInputCancel={() => handleSuggestionAddCancel("issues")}
              onItemEdit={(index) => handleSuggestionEdit(index, "issues")}
              onItemDelete={(index) => handleSuggestionDelete(index, "issues")}
            />
            <SuggestionColumn
              title="Possible solutions"
              dataItems={suggestions?.solutions}
              color="blue"
              haveAddButton={false}
              showAddInput={addSolution}
              addInputValue={suggestionValue?.solutions}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("solutions", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("solutions")}
              onAddInputCancel={() => handleSuggestionAddCancel("solutions")}
              onItemEdit={(index) => handleSuggestionEdit(index, "solutions")}
              onItemDelete={(index) =>
                handleSuggestionDelete(index, "solutions")
              }
            />
          </div>
          <div className="feedback">
            <h3>Leave a feedback for Check-in</h3>
            <div className="flex gap-20">
              <Button>Request feedback</Button>
              <Button>Schedule 1-1</Button>
            </div>
          </div>
          <div className="charts">
            <div className="chart">
              <LineChartCheckins
                title="Your Checkins"
                // labels={chartDateLabels}
                datasets={lineChartDatasets}
                chartOptions={lineChartOptions}
                filterOptions={dateFilterOptions}
                // onFilterChange={handleDateFilterChange}
                onChartFilterChange={handleChartFilterChange}
              />
              <DoughnutChartCheckins
                options={doughnutChartOptions}
                labels={doughnutChartLabels}
                datasets={doughnutChartDatasets}
                filterOptions={dateFilterOptions}
              />
            </div>
            <hr />
            <div className="chart">
              <LineChartCheckins
                title="Mood of your Team"
                // labels={chartDateLabels}
                datasets={lineChartDatasets}
                chartOptions={lineChartOptions}
                filterOptions={dateFilterOptions}
                // onFilterChange={handleDateFilterChange}
                onChartFilterChange={handleChartFilterChange}
              />
              <DoughnutChartCheckins
                options={doughnutChartOptions}
                labels={doughnutChartLabels}
                datasets={doughnutChartDatasets}
                filterOptions={dateFilterOptions}
                chartType="team"
              />
            </div>
            <hr />
            <div className="chart">
              <LineChartCheckins
                title="Mood of the Teams"
                // labels={chartDateLabels}
                datasets={lineChartDatasets}
                chartOptions={lineChartOptions}
                filterOptions={dateFilterOptions}
                chartFilterMultiselect={false}
                // onFilterChange={handleDateFilterChange}
                onChartFilterChange={handleChartFilterChange}
              />
              <div className="teams-chart">
                <div className="title-line">
                  <h4 className="select-team-title">Select Teams</h4>
                  <TypeAhead
                    placeholder="Add Team"
                    slug={(option) => option?.name}
                    options={teamsState || []}
                    icon={<AddIcon />}
                    multiple
                    handleSearch={(searchTerm) => handleTeamSearch(searchTerm)}
                    setSelected={handleTypeAheadSelect}
                    selected={selectedTeams}
                  />
                </div>
                {selectedTeams.length > 0 && (
                  <div className="selected-teams">
                    {selectedTeams?.map((team, index) => (
                      <div key={`team-${index}`} className="selected-team">
                        <div
                          className="dot"
                          style={{
                            backgroundColor: checkinsFilterColors[index],
                          }}
                        />
                        <p>{team?.name}</p>
                        <Button
                          variant="control"
                          handleClick={() => {
                            handleRemoveTeam(team?.id);
                          }}
                        >
                          <SubtractIcon />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
                <hr />
                <DoughnutChartCheckins
                  options={doughnutChartOptions}
                  labels={doughnutChartLabels}
                  datasets={doughnutChartDatasets}
                  filterOptions={dateFilterOptions}
                  chartType="teams"
                  barChartData={barChartData}
                  onChartHover={handleDoughnutHover}
                />
              </div>
            </div>
            <hr />
            <div className="chart">
              <LineChartCheckins
                title="Company mood"
                // labels={chartDateLabels}
                datasets={lineChartDatasets}
                chartOptions={lineChartOptions}
                filterOptions={dateFilterOptions}
                // onFilterChange={handleDateFilterChange}
                onChartFilterChange={handleChartFilterChange}
              />
              <DoughnutChartCheckins
                options={doughnutChartOptions}
                labels={doughnutChartLabels}
                datasets={doughnutChartDatasets}
                filterOptions={dateFilterOptions}
                chartType="company"
              />
            </div>
          </div>
        </div>
        <CheckinsHistory
          today={today}
          history={checkinsHistoryState}
          show={showHistory}
          onClose={handleHistoryClose}
          onHistoryClick={handleHistoryItemClick}
          onCurrentClick={handleHistoryCurrentClick}
        />
      </div>
    </div>
  );
};

export default CheckIns;
