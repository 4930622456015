const titles = {
  "/": "Career Path",
  "/chat": "Chat",
  "/goal": "Goals",
  "/reports": "Reports",
  "/reports/chat": "Chat",
  "/reports/communication": "Communication",
  "/profile": "Profile",
  "/meetings": "Meetings",
  "/support": "Support",
  "/settings": "Settings",
  "/projects": "Projects",
  "/survey": "Survey",
  "/survey/ongoing": "Ongoing surveys",
  "/survey/completed": "Completed",
  "/survey/drafts": "Drafts",
  "/survey/archived": "Archived",
  "/career": "Career",
  "/login": "Login",
  "/administration": "Administration",
  "/administration/teams": "Teams",
  "/administration/users": "Users",
  "/administration/achievements": "Achievements",
  "/administration/roles": "Roles",
  "/administration/skills": "Skills",
  "/administration/responsibilities": "Responsibilitys",
  "/administration/behaviours": "Behaviours",
  "/administration/interests": "Interests",
  "/administration/meeting-types": "Meeting Types",
  "/career-path": "Career Path",
  "/your-career": "Your Career",
  "/personal-performance": "Personal Performance",
};

export default titles;
