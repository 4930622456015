import React, { useEffect, useState } from "react";
import { Filter, LineChart } from "../../../../ui";
import moment from "moment";
import { checkinsFilterColors, checkinsTypes } from "../../../../../data/types";
import ChartFilterOption from "./ChartFilterOption";

const LineChartCheckins = ({
  title = "",
  // labels = [],
  datasets = [],
  chartOptions = [],
  chartFilterMultiselect = true,
  filterOptions = [],
  filterType = "date",
  // onFilterChange = () => {},
  onChartFilterChange = () => {},
}) => {
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartFilters, setChartFilters] = useState([]);
  const [selectedChartFilter, setSelectedChartFilter] = useState(null);

  const images = checkinsTypes.map((type) =>
    require(`../images/${type.imageName}.png`)
  );

  useEffect(() => {
    if (filterType === "date") {
      handleDateFilterChange(selectedFilter);
    }
  }, [selectedFilter]);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleFilterReset = () => {
    setSelectedFilter(1);
  };

  const handleChartFilterClick = (index) => {
    if (!chartFilterMultiselect) {
      setSelectedChartFilter(index);
    }
    onChartFilterChange(index);
  };

  const yearsChartFilters = () => {
    let years = [];
    for (let i = 12; i >= 0; i--) {
      years.push(moment(new Date()).subtract(i, "years").format("YYYY"));
    }
    return years;
  };

  const handleDateFilterChange = (value) => {
    if (value === 0) {
      setChartFilters(yearsChartFilters());
      setChartLabels(moment.monthsShort());
      return;
    }
    if (value === 1) {
      setChartFilters(moment.monthsShort());
      setChartLabels(Array.from({ length: 31 }, (_, i) => i + 1));
      return;
    }
    if (value === 2) {
      setChartFilters(["Week 1", "Week 2", "Week 3", "Week 4"]);
      setChartLabels(moment.weekdays());
      return;
    }
  };

  return (
    <div className="line-chart">
      <div className="title-line">
        <h4 className="title">{title}</h4>
        <Filter
          filters={filterOptions}
          checked={selectedFilter}
          onRadioChange={(e) => handleFilterChange(parseInt(e?.target?.value))}
          onReset={handleFilterReset}
        />
      </div>
      <LineChart
        labels={chartLabels}
        datasets={datasets}
        chartOptions={chartOptions}
        outsideTitle="Check-in mood"
      >
        {/* <div className="chart-labels"> */}
        {images.reverse().map((image, index) => (
          <div key={`label-${index}`} className="icon-wrapper">
            <img src={image} alt={checkinsTypes[index]?.imageName} />
          </div>
        ))}
        {/* </div> */}
      </LineChart>
      <div className="chart-filters">
        {filterType === "date" && (
          <>
            {chartFilters?.map((filter, index) => (
              <ChartFilterOption
                key={`filter-option-${index}`}
                option={filter}
                color={
                  chartFilterMultiselect
                    ? checkinsFilterColors[index]
                    : "#263E4E"
                }
                multiselect={chartFilterMultiselect}
                isSelected={selectedChartFilter === index ? true : false}
                onClick={() => handleChartFilterClick(index)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default LineChartCheckins;
