import { useEffect, useState } from "react";
import { TrendCard, Filter } from "../../ui";
import AllMeetingsChart from "./charts/AllMeetingsChart";
import "./Dashboard.scss";
import TypeStatisticsChart from "./charts/TypeStatisticsChart";
import TalkingPointsChart from "./charts/TalkingPointsChart";
import FeedbackStatisticsChart from "./charts/FeedbackStatisticsChart";
import PriorityStatisticsChart from "./charts/PriorityStatisticsChart";
import {
  fetchMeetingsStatistics,
  fetchMeetingsPriority,
  fetchTalkingPoints,
  fetchMeetingsTypeStatistics,
  fetchMeetingTypes,
  fetchLeaderboardAmount,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useSelector } from "react-redux";
import MeetingsAmountList from "./MeetingsAmountList";
import { allMonths } from "../../../data/dateTime";

const dummyFeedbacks = [
  {
    value: 45,
    label: "Enabled",
    color: "#7ED3D1",
  },
  {
    value: 55,
    label: "Disabled",
    color: "#D9B8FF",
  },
];

const Dashboard = () => {
  const [filters, setFilters] = useState({
    typeStatisticsMonth: null,
    talkingPointsMonth: null,
    priorityMonth: null,
    feedbackMonth: null,
    leaderboardMonth: null,
  });

  const statisticsState = useSelector(
    (state) => state?.meetings
  )?.meetingsStatistics;
  const priorityState = useSelector(
    (state) => state?.meetings
  )?.meetingsPriority;
  const talkingPointsState = useSelector(
    (state) => state?.meetings
  )?.talkingPoints;
  const typeStatisticsState = useSelector(
    (state) => state?.meetings
  )?.typeStatistics;
  const allMeetingTypesState = useSelector(
    (state) => state?.meetingTypes
  )?.meetingTypes;
  const leaderboardAmount = useSelector(
    (state) => state?.meetings
  )?.leaderboardAmount;

  const [doFetchMeetingsStatistics] = useThunk(fetchMeetingsStatistics);
  const [doFetchMeetingsPriority] = useThunk(fetchMeetingsPriority);
  const [doFetchTalkingPoints] = useThunk(fetchTalkingPoints);
  const [doFetchMeetingsTypeStatistics] = useThunk(fetchMeetingsTypeStatistics);
  const [doFetchMeetingTypes] = useThunk(fetchMeetingTypes);
  const [doFetchLeaderboardAmount] = useThunk(fetchLeaderboardAmount);

  useEffect(() => {
    doFetchMeetingsStatistics();
    doFetchMeetingsPriority();
    doFetchTalkingPoints();
    doFetchMeetingsTypeStatistics();
    doFetchMeetingTypes();
    doFetchLeaderboardAmount();
  }, []);

  const meetingsCards = [
    "totalMeetings",
    "totalMeetingsPerMonth",
    "totalMeetingsCancelled",
    "totalMeetingsCancelledPerMonth",
    "totalTimeSpentOnMeetingsPerMonth",
    "avgNumberOfParticipantsPerMonth",
    "avgMonthlyTimePerUserOnMeeting",
  ];

  const handleFilterSelect = (filterType, event, fetchFn) => {
    const value = parseInt(event.target.value);
    setFilters((prev) => ({ ...prev, [filterType]: value }));
    fetchFn(value);
  };

  // General handler to reset filters
  const handleResetFilter = (filterType, fetchFn) => {
    setFilters((prev) => ({ ...prev, [filterType]: null }));
    fetchFn();
  };
  return (
    <div className="meetings-dashboard">
      <div className="meetings-dashboard-header flex justify-between items-center">
        <h2>Meetings Dashboard</h2>
      </div>
      <div className="meetings-statistics">
        <h2>Meetings statistics</h2>
        <div className="meetings-statistics-items flex gap-24">
          {meetingsCards?.map((itemKey) => {
            const itemData = statisticsState?.[itemKey]?.[0];

            return (
              <TrendCard
                label={itemData?.name}
                count={itemData?.value}
                percentage={itemData?.percentage}
                key={`trend-card-${itemKey}`}
              />
            );
          })}
        </div>
      </div>
      <div className="all-meetings">
        <div className="all-meetings-block w-50">
          <h2>All Meetings</h2>
          <div className="meetings-bar-chart-wrapper">
            <h3>Leader-board for the time spent on meetings</h3>
            <AllMeetingsChart
              dataPoints={statisticsState?.usersLeaderBoard}
              statisticsState={statisticsState}
            />
          </div>
        </div>
        <div className="all-meetings-block w-20 all-meetings-middle-block">
          <div className="all-meetings-block-header">
            <h3>Meeting cost and Total time</h3>
          </div>
          <div className="all-meetings-block-content">
            <TrendCard
              label={statisticsState?.meetingCostForAll[0].name}
              count={`${statisticsState?.meetingCostForAll[0].value}$`}
              percentage={statisticsState?.meetingCostForAll[0].percentage}
            />
            <TrendCard
              label={statisticsState?.totalTimeSpentOnMeetingsPerMonth[0].name}
              count={`${statisticsState?.totalTimeSpentOnMeetingsPerMonth[0].value}h`}
              percentage={
                statisticsState?.totalTimeSpentOnMeetingsPerMonth[0].percentage
              }
            />
          </div>
        </div>
        <div className="all-meetings-block type-statistics-block w-30">
          <div className="meetings-block-header">
            <h3>Type statistics</h3>
            <div className="single-view-filters">
              <Filter
                filters={allMonths}
                checked={filters.typeStatisticsMonth}
                onRadioChange={(e) =>
                  handleFilterSelect(
                    "typeStatisticsMonth",
                    e,
                    doFetchMeetingsTypeStatistics
                  )
                }
                onReset={() =>
                  handleResetFilter(
                    "typeStatisticsMonth",
                    doFetchMeetingsTypeStatistics
                  )
                }
              />
            </div>
          </div>
          <TypeStatisticsChart
            typeData={typeStatisticsState}
            allTypes={allMeetingTypesState}
          />
        </div>
      </div>
      <div className="talking-points">
        <div className="talking-points-block w-50">
          <div className="meetings-block-header">
            <h2>Talking points and Priority statistics</h2>
            <div className="single-view-filters">
              <Filter
                filters={allMonths}
                checked={filters.talkingPointsMonth}
                onRadioChange={(e) =>
                  handleFilterSelect(
                    "talkingPointsMonth",
                    e,
                    doFetchTalkingPoints
                  )
                }
                onReset={() =>
                  handleResetFilter("talkingPointsMonth", doFetchTalkingPoints)
                }
              />
            </div>
          </div>
          <div className="meetings-bar-chart-wrapper">
            <h3>Talking points leader-board</h3>
            <div className="meetings-chart-wrapper">
              <TalkingPointsChart dataPoints={talkingPointsState} />
            </div>
          </div>
        </div>
        <div className="flex w-50">
          <div className="w-50 all-meetings-block bordered-left">
            <div className="meetings-block-header">
              <h3>Priority statistics</h3>
              <div className="single-view-filters">
                <Filter
                  filters={allMonths}
                  checked={filters.priorityMonth}
                  onRadioChange={(e) =>
                    handleFilterSelect(
                      "priorityMonth",
                      e,
                      doFetchMeetingsPriority
                    )
                  }
                  onReset={() =>
                    handleResetFilter("priorityMonth", doFetchMeetingsPriority)
                  }
                />
              </div>
            </div>
            <div className="all-meetings-block-content">
              <PriorityStatisticsChart chartData={priorityState} />
            </div>
          </div>
          <div className="w-50 all-meetings-block bordered-left">
            <div className="meetings-block-header">
              <h3>Feedback statistics</h3>
              <div className="single-view-filters">
                <Filter
                  filters={allMonths}
                  checked={filters.feedbackMonth}
                  onRadioChange={(e) =>
                    handleFilterSelect("feedbackMonth", e, () => {})
                  }
                  onReset={() => handleResetFilter("feedbackMonth", () => {})}
                />
              </div>
            </div>
            <div className="all-meetings-block-content">
              <FeedbackStatisticsChart
                typeData={dummyFeedbacks}
                feedbacks={dummyFeedbacks}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="meetings-amout">
        <div className="meetings-amount-header meetings-block-header">
          <h2>Meeting leader-board per amount</h2>
          <div className="single-view-filters">
            <Filter
              filters={allMonths}
              checked={filters.leaderboardMonth}
              onRadioChange={(e) =>
                handleFilterSelect(
                  "leaderboardMonth",
                  e,
                  doFetchLeaderboardAmount
                )
              }
              onReset={() =>
                handleResetFilter("leaderboardMonth", doFetchLeaderboardAmount)
              }
            />
          </div>
        </div>
        <MeetingsAmountList leaderboardAmount={leaderboardAmount} />
      </div>
    </div>
  );
};

export default Dashboard;
