import { useState, useEffect } from "react";

import { Form, Input, Modal } from "../../../ui";

import { useSelector } from "react-redux";

const ResponsibilitiesModal = ({ showModal, handleModalClose, handleSave }) => {
  const [responsibility, setResponsibility] = useState(null);

  const formType = useSelector((state) => state.form)?.type;
  const formData = useSelector((state) => state.form)?.data;

  useEffect(() => {
    if (!!formData && formType === "edit") {
      setResponsibility(formData);
    }
  }, [formData]);

  const handleChange = (event, customName) => {
    if (customName) {
      setResponsibility({ ...responsibility, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setResponsibility({ ...responsibility, [name]: value });
  };

  const modalTitle =
    formType === "create" ? "Add Resposibility" : "Edit Responsibility";

  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      handleConfirm={() => handleSave(responsibility)}
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <label>Name</label>
          <Input
            type="input"
            placeholder="Add responsibility"
            value={responsibility?.name || ""}
            handleChange={handleChange}
            name="name"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ResponsibilitiesModal;
