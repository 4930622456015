import { useCallback, useEffect, useState } from "react";

import {
  fetchEmployees,
  fetchUsers,
  createUser,
  updateUser,
  fetchPositions,
  createEmployee,
  deleteEmployee,
  updateEmployee,
  fetchTeams,
} from "../../../../store";
import { seniorityTypes } from "../../../../data/types";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import "./employees.scss";

import { SearchIcon, AddIcon, CalendarIcon } from "../../../../icons";

import Select from "react-select";

import { Button, Input, Modal, Table, Form } from "../../../ui";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetEmployeesMessage } from "../../../../store/employees/employeesSlice";

const Employees = () => {
  const [doFetchEmployees] = useThunk(fetchEmployees);
  const [doCreateEmployee] = useThunk(createEmployee);
  const [doDeleteEmployee] = useThunk(deleteEmployee);
  const [doUpdateEmployee] = useThunk(updateEmployee);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateUser] = useThunk(createUser);
  const [doUpdateUser] = useThunk(updateUser);
  const [doFetchPositions] = useThunk(fetchPositions);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doShowToastMessage] = useToastMessage(resetEmployeesMessage);

  const employeesState = useSelector((state) => state.employees)?.data;
  const usersState = useSelector((state) => state.users)?.data?.userData;
  const positionsState = useSelector((state) => state.positions)?.data;
  const teamsState = useSelector((state) => state.teams)?.teams;
  const messageState = useSelector((state) => state.employees)?.message;

  useEffect(() => {
    doFetchEmployees();
    doFetchUsers({});
    doFetchPositions();
    doFetchTeams({});
  }, []);

  const [employeesData, setEmployeesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [formType, setFormType] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    if (!!selectedEmployee) {
      setEmployeesData(selectedEmployee);
    } else {
      setEmployeesData(null);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const columns = [
    { id: 1, label: "Employee", slug: "employee" },
    { id: 2, label: "Position", slug: "position_title" },
    { id: 3, label: "Email", slug: "email" },
    { id: 4, label: "Phone", slug: "phone" },
    { id: 5, label: "Projects", slug: "projects" },
  ];

  const userActiveValues = [
    { value: "", label: "Select One" },
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const employeesUsers = employeesState.map((employee) => employee.user.id);
    let reducedUsers = usersState?.filter((user) => {
      if (!selectedEmployee) {
        return !employeesUsers.includes(user.id);
      }
      return (
        !employeesUsers.includes(user.id) ||
        user.id === selectedEmployee.user.id
      );
    });

    setFilteredUsers(reducedUsers);
  }, [positionsState, usersState, selectedEmployee]);

  const filterPositions = () => {
    const filteredEmployees = employeesState?.filter((employee) => {
      return (
        employee?.user?.firstName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        employee?.user?.lastName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });
    setEmployeesData(filteredEmployees);
  };

  useEffect(() => {
    filterPositions();
  }, [searchTerm]);

  useEffect(() => {
    if (!filteredUsers) {
      doFetchUsers({});
    }
    const reducedUsers = filteredUsers?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [filteredUsers, selectedEmployee]);

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedEmployee(null);
  };

  const handleChange = (event, customName) => {
    if (customName) {
      setEmployeesData({ ...employeesData, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setEmployeesData({ ...employeesData, [name]: value });
  };

  const setUserBirthday = (date) => {
    setEmployeesData({
      ...employeesData,
      birthday: moment(date).format("DD.MM.yyy"),
    });
  };

  const handleSave = () => {
    if (formType === "create") {
      doCreateUser(employeesData);
    } else {
      doUpdateUser(employeesData);
    }
    setShowModal(false);
    setSelectedEmployee(null);
  };

  const handleDeleteEmployee = (employeeId) => {
    doDeleteEmployee(employeeId);
  };

  const handleShowModal = (type, employee) => {
    if (type === "edit") {
      const selectedEmployees = employeesState.find(
        (selectedEmployee) => selectedEmployee.id === employee.id
      );
      setSelectedEmployee(selectedEmployees);
    }

    setFormType(type);
    setShowModal(true);
  };
  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const selectedTeam = () => {
    let team = teamsState?.optionData?.find(
      (item) => item?.id === employeesData?.team
    );
    if (team) {
      return {
        value: team?.id,
        label: team?.name,
        isFixed: true,
      };
    }
    return "";
  };

  const selectedPosition = () => {
    let position = null;
    if (typeof employeesData?.position === "number") {
      position = positionsState?.find(
        (item) => item?.id === employeesData?.position
      );
    } else {
      position = positionsState?.find(
        (item) => item?.id === employeesData?.position_id
      );
    }
    if (position) {
      return {
        value: position?.id,
        label: position?.title,
        isFixed: true,
      };
    }
    return "";
  };

  const tableData = {
    columns: columns,
    rows: employeesState.map((employee) => ({
      id: employee.id,
      employee: `${employee.user.firstName} ${employee.user.lastName}`,
      position_title: employee.position_title,
      email: employee.user.email,
      phone: employee.user.phone,
      projects:
        employee.user.projects.length === 0
          ? "No projects"
          : employee.user.projects
              .slice(0, 3)
              .map((project) => project.name)
              .join(", ") + (employee.user.projects.length > 3 ? "..." : ""),
    })),
  };

  return (
    <div className="carrer-content">
      <div className="career-header">
        <h2>Employees</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              handleChange={handleSearch}
              icon={<SearchIcon />}
              value={searchTerm}
            />
          </div>
          <Button handleClick={() => handleShowModal("create")}>
            <AddIcon />
            Add employee
          </Button>
        </div>
      </div>
      <div className="career-content employee-content">
        <Table
          rows={tableData.rows}
          columns={tableData.columns}
          isAdmin={true}
          hasActions={true}
          handleEdit={(row) => handleShowModal("edit", row)}
          handleDelete={(row) => handleDeleteEmployee(row.id)}
        />
        <Modal
          show={showModal}
          handleClose={handleModalClose}
          title={formType === "create" ? "Add Employee" : "Edit Employee"}
          handleConfirm={handleSave}
          disabledConfirm={
            !employeesData?.levelOfSeniority ||
            !employeesData?.timeInPositionYears ||
            !employeesData?.experienceYears
          }
          confirmButtonLabel="Save"
          rejectButtonLabel="Close"
        >
          <Form>
            <div className="form-group">
              <Input
                type="input"
                label="First name"
                placeholder="Enter first name"
                value={employeesData?.user?.first_name || ""}
                handleChange={handleChange}
                name="first_name"
              />
            </div>
            <div className="form-group">
              <Input
                type="input"
                label="Last name"
                placeholder="Enter last name"
                value={employeesData?.last_name || ""}
                handleChange={handleChange}
                name="last_name"
              />
            </div>
            <div className="form-group">
              <Input
                type="email"
                label="Email"
                placeholder="Enter email"
                value={employeesData?.email || ""}
                handleChange={handleChange}
                name="email"
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                label="Password"
                placeholder="Enter password"
                handleChange={handleChange}
                name="password"
              />
            </div>
            {/* <div className="form-group">
                <label>Image</label>
                <div className="custom-input">
                  <Input
                    type="file"
                    placeholder="Select a profile image to upload"
                    name="image"
                    handleChange={changeHandler}
                  />
                  <span>
                    {existingImage?.title ||
                      employeesData?.image ||
                      "Select a profile image to upload"}
                  </span>
                </div>
              </div> */}
            <div className="form-group">
              <Input
                type="number"
                label="Phone"
                placeholder="Enter phone"
                value={employeesData?.phone || ""}
                handleChange={handleChange}
                name="phone"
              />
            </div>
            <div className="date-picker-holder">
              <label>Birthday</label>
              <DatePicker
                onChange={setUserBirthday}
                dateFormat="dd.MM.yyyy"
                dateFormatCalendar={"MMM yyyy"}
                placeholderText="Enter due date"
                minDate={subMonths(new Date(), 840)}
                maxDate={addMonths(new Date(), 0)}
                showMonthDropdown
                showYearDropdown
                value={employeesData?.birthday}
              />
              <CalendarIcon />
            </div>
            <div className="form-group">
              <label>Job position</label>
              <Select
                name="position"
                placeholder="Select Position"
                options={positionsState?.map((position) => ({
                  value: position?.id,
                  label: position?.title,
                  isFixed: true,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedPosition()}
                onChange={(event) => handleChange(event, "position")}
              />
            </div>
            <div className="form-group">
              <label>Team</label>
              <Select
                name="team"
                placeholder="Select Team"
                options={teamsState?.optionData?.map((team) => ({
                  value: team?.id,
                  label: team?.name,
                  isFixed: true,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleChange(event, "team")}
                value={selectedTeam()}
              />
            </div>
            {/* {formType === "edit" && !employeesData ? (
                <></>
              ) : (
                <div className="form-group">
                  <label>Active</label>
                  <Select
                    menuPlacement="top"
                    name="active"
                    placeholder="Select Active"
                    options={userActiveValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(event) => handleChange(event, "active")}
                    defaultValue={userActiveValues?.find(
                      (item) => item?.value === employeesData?.active
                    )}
                  />
                </div>
              )} */}
            <div className="form-group">
              <Input
                type="input"
                label="Experience In Years"
                placeholder="Enter experience"
                name="experienceYears"
                value={employeesData?.experienceYears || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="form-group">
              <Input
                type="input"
                label="Time In Position"
                placeholder="Enter time in position"
                name="timeInPositionYears"
                value={employeesData?.timeInPositionYears || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Level of seniority</label>
              <DropdownSelect
                options={seniorityTypes}
                handleSelect={handleChange}
                type="primary"
                name="levelOfSeniority"
                background="dark"
                preselected={{
                  value: parseInt(employeesData?.levelOfSeniority),
                  label: seniorityTypes.find(
                    (type) =>
                      type.value === parseInt(employeesData?.levelOfSeniority)
                  )?.label,
                }}
              />
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Employees;
