import React from "react";

const SubtractIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88421 7.35003L7.47368 7.35003L10.0632 7.35003M7.47368 0.99986C11.049 0.999859 13.9474 3.84293 13.9474 7.35003C13.9474 10.8571 11.049 13.7002 7.47369 13.7002C3.89837 13.7002 1 10.8571 1 7.35003C1 3.84293 3.89837 0.99986 7.47368 0.99986Z"
        stroke="#666666"
      />
    </svg>
  );
};

export default SubtractIcon;
