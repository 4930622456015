import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import { personalPerformanceViews } from "./views";
import { Button } from "../ui";
import { useDispatch, useSelector } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import { fetchSingleUser, fetchTeams, updateUser } from "../../store";
import { seniorityTypes } from "../../data/types";
import { CircleCheckIcon, CloseIcon, EditIcon } from "../../icons";
import { useLocation } from "react-router-dom";
import {
  resetSingleUser,
  resetUserReviewTemplate,
} from "../../store/users/usersSlice";

const PersonalPerformance = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const [doFetchTeams] = useThunk(fetchTeams);
  const [doFetchSingleUser] = useThunk(fetchSingleUser);
  const [doUpdateUser] = useThunk(updateUser);

  const currentUserState = useSelector((state) => state?.users)?.singleUser;
  const teamsState = useSelector((state) => state.teams?.teams)?.optionData;

  const [expandedViews, setExpandedViews] = useState([]);
  const [selectedUser, setSelectedUser] = useState(currentUserState);
  const [isAboutEditEnabled, setIsAboutEditEnabled] = useState(false);
  const [aboutMe, setAboutMe] = useState(localUserData?.about_me || "");

  const handleToggleView = (id) => {
    setExpandedViews((prev) =>
      prev.includes(id)
        ? prev.filter((childId) => childId !== id)
        : [...prev, id]
    );
  };

  useEffect(() => {
    doFetchTeams({});
    if (!!state && currentUserState?.id !== state?.userId) {
      doFetchSingleUser(state?.userId);
    } else {
      doFetchSingleUser(localUserData?.id);
    }
    return () => {
      dispatch(resetSingleUser());
      dispatch(resetUserReviewTemplate());
    };
  }, []);

  useEffect(() => {
    if (!!currentUserState) {
      setSelectedUser(currentUserState);
      if (!!state?.expandedViews) {
        setExpandedViews(state?.expandedViews);
      }
    }
  }, [currentUserState]);

  const handleEditAboutMe = () => {
    setIsAboutEditEnabled(true);
  };

  const handleConfirmAboutMe = () => {
    setIsAboutEditEnabled(false);
    const {
      id,
      active,
      birthday,
      email,
      first_name,
      last_name,
      phone,
      team,
      manager,
    } = localUserData;
    doUpdateUser({
      id,
      active,
      birthday,
      email,
      first_name,
      last_name,
      phone,
      team,
      manager: manager?.id,
      about_me: aboutMe,
    });
  };

  const handleCancelAboutMe = () => {
    setIsAboutEditEnabled(false);
    setAboutMe(localUserData?.about_me || "");
  };

  const handleTextAreaChange = (value) => {
    setAboutMe(value);
  };

  return (
    <div className="personal-performance-wrapper">
      <div className="personal-performance-content">
        <div className="personal-performance-header">
          <UserCard />
          <div className="header-info">
            <div className="about-me-wrapper">
              <textarea
                rows="3"
                placeholder="About me:"
                readOnly={!isAboutEditEnabled}
                value={aboutMe}
                onChange={(e) => handleTextAreaChange(e.target.value)}
              />
              <div className="action-wrapper">
                {!isAboutEditEnabled ? (
                  <Button variant="action" handleClick={handleEditAboutMe}>
                    <EditIcon />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="action confirm"
                      handleClick={handleConfirmAboutMe}
                    >
                      <CircleCheckIcon />
                    </Button>
                    <Button
                      variant="action close"
                      handleClick={handleCancelAboutMe}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="button-group">
              <Button variant="secondary">Assign review</Button>
              <Button>Search</Button>
            </div>
          </div>
        </div>
        <div className="personal-performance-user-info">
          <table>
            <thead>
              <tr>
                <td>Name Surname</td>
                <td>Position / Seniority</td>
                <td>Department / Team</td>
                <td>Mentor</td>
                <td>Mentees</td>
                <td>Current time in company</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</td>
                <td>
                  {!!selectedUser?.position
                    ? `${selectedUser?.position}`
                    : "None"}
                  {selectedUser?.levelOfSeniority &&
                    ` / ${
                      seniorityTypes.find(
                        (type) => type.value === selectedUser?.levelOfSeniority
                      ).label
                    }`}
                </td>
                {/* TODO: kada budemo imali department property, postaviti ga td */}
                <td>
                  {
                    teamsState?.find((team) => team?.id === selectedUser?.team)
                      ?.name
                  }
                </td>
                <td>{selectedUser?.mentor || "None"}</td>
                <td>{selectedUser?.mentees || "None"}</td>
                {/* TODO: promeeniti kada budemo imali datum prijave */}
                <td>Since: 2022-12-09</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="personal-performance-views">
          {personalPerformanceViews?.map((view) => (
            <React.Fragment key={`view-${view?.id}`}>
              <div
                className="performance-view"
                onClick={() => handleToggleView(view?.id)}
              >
                <div className="view-icon">{view?.icon}</div>
                <h3>{view?.label}</h3>
              </div>
              <div className="expandable-content">
                {view?.component({
                  isExpanded: expandedViews.includes(view?.id),
                  viewId: view?.id,
                })}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalPerformance;
