import { createSlice } from "@reduxjs/toolkit";

import {
  fetchPanelDiscussions,
  fetchPanelDiscussion,
  createPanelDiscussion,
  updatePanelDiscussion,
  deletePanelDiscussion,
} from "./panelDiscussionThunks";

const panelDiscussionSlice = createSlice({
  name: "panelDiscussion",
  initialState: {
    data: [],
    singlePanelDiscussion: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetPanelDiscussionMessage: (state) => {
      state.message = null;
    },
    resetSinglePanelDiscussion: (state) => {
      state.singlePanelDiscussion = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPanelDiscussions.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchPanelDiscussion.fulfilled, (state, action) => {
      state.singlePanelDiscussion = action.payload;
    });
    builder.addCase(deletePanelDiscussion.fulfilled, (state, action) => {
      const filteredPanelDiscussions = state?.data?.filter(
        (panelDiscussion) => panelDiscussion.id !== action.meta.arg
      );
      state.data = filteredPanelDiscussions;
      state.message = {
        type: "success",
        title: "Success",
        message: "Panel Discussion successfully deleted",
      };
    });
    builder.addCase(createPanelDiscussion.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      console.log(state.data);
      state.message = {
        type: "success",
        title: "Success",
        message: "Panel Discussion successfully created",
      };
    });
    builder.addCase(updatePanelDiscussion.fulfilled, (state, action) => {
      const updatedPanelDiscussions = state?.data?.map((panelDiscussion) => {
        if (panelDiscussion.id === action.payload.id) {
          return action.payload;
        }
      });
      state.data = updatedPanelDiscussions;
      state.message = {
        type: "success",
        title: "Success",
        message: "Panel Discussion successfully updated",
      };
    });
  },
});

export const { resetPanelDiscussionMessage, resetSinglePanelDiscussion } =
  panelDiscussionSlice.actions;

export const panelDiscussionReducer = panelDiscussionSlice.reducer;
