import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const FeedbackStatisticsChart = ({ typeData, feedbacks }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const doughnutData = {
    labels: [],
    datasets: [
      {
        label: "Number of meetings",
        data: typeData?.map((item) => item?.value),
        backgroundColor: typeData?.map((item) => item?.color),
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="type-statistics-content flex justify-between">
      <div className="type-statistics-labels">
        <h4>Type</h4>
        <ul>
          {feedbacks?.map((type) => (
            <li key={`meeting-type-${type?.label}`}>
              <span
                className="type-circle"
                style={{ backgroundColor: type?.color }}
              ></span>
              <div className="type-statistics-label">
                <p>{type?.label}</p>
              </div>
              <p>{type?.value} %</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="type-statistics-chart">
        <div className="doughnut-wrapper" style={{ maxWidth: "200px" }}>
          <Doughnut data={doughnutData} />
        </div>
      </div>
    </div>
  );
};

export default FeedbackStatisticsChart;
