import React, { useState, useEffect } from "react";
import Checkbox from "../Checkbox";
import OutsideClick from "../OutsideClick";
import { Button } from "../../ui";
import { CloseIcon } from "../../../icons";
import "./styles.scss";

const Dropdown = ({
  options = [],
  placeholder = "",
  name = "",
  className,
  preselected = null,
  isMultiSelect = false,
  slug = "value",
  handleSelect = () => {},
}) => {
  const [selected, setSelected] = useState(isMultiSelect ? [] : "");
  const [isOptionsHidden, setIsOptionsHidden] = useState(true);

  /* Preselected */
  const areArraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };
  useEffect(() => {
    if (isMultiSelect) {
      if (
        preselected &&
        !areArraysEqual(preselected, selected) &&
        !selected?.length
      ) {
        setSelected(preselected);
      }
    } else {
      if (preselected && preselected !== selected) {
        setSelected(preselected);
      }
    }
  }, [preselected]);

  /* Handle select */

  const handleOptionClick = (selectedValue) => {
    handleSelect(parseInt(selectedValue));
    setSelected(parseInt(selectedValue));
  };

  const handleMultipleOptionClick = (option) => {
    const optionId = option.id || option[slug];
    if (
      selected.some(
        (selectedOption) =>
          (selectedOption.id || selectedOption[slug]) === optionId
      )
    ) {
      handleSelect(
        name,
        selected.filter(
          (multiOption) => (multiOption.id || multiOption[slug]) !== optionId
        )?.value
      );
      setSelected(
        selected.filter(
          (multiOption) => (multiOption.id || multiOption[slug]) !== optionId
        )
      );
    } else {
      handleSelect(name, [...selected, option]);
      setSelected([...selected, option]);
    }
  };

  const handleOutsideClick = () => {
    setIsOptionsHidden(true);
  };

  const handleClearClick = () => {
    if (!isMultiSelect) {
      setSelected("");
    } else {
      setSelected([]);
    }
    setIsOptionsHidden(true);
  };

  const removeSingleItem = (e, selectedOption) => {
    e.stopPropagation();
    const filteredList = selected?.filter(
      (option) => option[slug] !== selectedOption[slug]
    );
    setSelected(filteredList);
  };

  return (
    <OutsideClick onOutsideClick={handleOutsideClick}>
      <div
        className={`dropdown-wrapper ${className}`}
        data-options-hidden={isOptionsHidden}
      >
        <div
          className="dropdown-input flex justify-between"
          onClick={() => setIsOptionsHidden(!isOptionsHidden)}
        >
          {!isMultiSelect ? (
            <span className="dropdown-single-seleced-label">
              {selected
                ? options.find((option) => option[slug] === selected)?.label ||
                  selected
                : placeholder}
            </span>
          ) : (
            <ul className="multi-results">
              {!selected?.length ? (
                <li className="placeholder">{placeholder}</li>
              ) : (
                selected?.map((option) => (
                  <li
                    key={option?.id}
                    className="selected-item"
                    onClick={(e) => removeSingleItem(e, option)}
                  >
                    {option?.label}
                    <CloseIcon />
                  </li>
                ))
              )}
            </ul>
          )}

          <div className="btn-container">
            <span className="vertical-line" />
            {!!selected?.length ? (
              <Button variant="control" handleClick={handleClearClick}>
                <CloseIcon />
              </Button>
            ) : (
              <div className="arrow-down"></div>
            )}
          </div>
        </div>
        <div className="dropdown-select">
          {!isMultiSelect ? (
            <div className={`options ${isOptionsHidden ? "hidden" : ""}`}>
              {options.map((option, index) => {
                const optionId = option.id || option[slug];
                return (
                  <Checkbox
                    key={`rad-${index}`}
                    type="radio"
                    value={optionId}
                    onChange={(e) => handleOptionClick(e.target.value)}
                    checked={optionId === selected}
                  >
                    {option?.label || option}
                  </Checkbox>
                );
              })}
            </div>
          ) : (
            <div className={`options ${isOptionsHidden ? "hidden" : ""}`}>
              {options.map((option, index) => {
                const optionId = option.id || option[slug];
                return (
                  <Checkbox
                    key={`checkbox-${index}`}
                    value={optionId}
                    onChange={() => handleMultipleOptionClick(option)}
                    checked={selected.some(
                      (selectedOption) =>
                        (selectedOption.id || selectedOption[slug]) === optionId
                    )}
                  >
                    {option?.label || option}
                  </Checkbox>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </OutsideClick>
  );
};

export default Dropdown;
