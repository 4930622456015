import { useEffect, useState, useRef } from "react";
import { fetchFeedbacks, fetchProjectsTasks } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";

import { performanceTaskStatuses } from "../../../../data/statuses";
import { Status, CardFilter, Filter, Button } from "../../../ui";

import { Pie } from "react-chartjs-2";

import "./styles.scss";
import { SingleArrow } from "../../../../icons";
import PreviewFeedbackForm from "../../../CareerPath/client/forms/PreviewFeedbackForm";
import GiveFeedbackForm from "../../../CareerPath/client/forms/GiveFeedbackForm";
import RequestFeedbackForm from "../../../CareerPath/client/forms/RequestFeedbackForm";

const FeedbacksAndTasks = ({ isExpanded }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showPreviewFeedbackModal, setShowPreviewFeedbackModal] =
    useState(false);
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false);
  const [showRequestFeedbackModal, setShowRequestFeedbackModal] =
    useState(false);

  const [doFetchProjectsTasks] = useThunk(fetchProjectsTasks);
  const [doFetchFeedbacks] = useThunk(fetchFeedbacks);

  const projectsTasksState = useSelector((state) => state.tasks)?.projectsTasks;
  const projectsTasksMetaState = useSelector(
    (state) => state.tasks
  )?.projectsTasksMeta;
  const feedbacksState = useSelector((state) => state?.feedback)?.feedbacks;

  const onlyPositiveTasksCount =
    projectsTasksMetaState?.totalPerStatus &&
    Object.entries(projectsTasksMetaState?.totalPerStatus)
      .filter(([key, value]) => value !== 0)
      .map(([key, value]) => parseInt(key, 10));

  const onlyPositiveCountStatuses = performanceTaskStatuses.filter((status) =>
    onlyPositiveTasksCount?.includes(status.value)
  );

  const statusColors = {
    blue: "#14b4ba",
    green: "#a9ce5b",
    red: "#f07171",
    purple: "#96a3e4",
    yellow: "#f0e371",
  };

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      doFetchProjectsTasks({});
      doFetchFeedbacks(localUserData?.id);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (
      isExpanded &&
      contentRef.current &&
      !!projectsTasksState?.length &&
      !!feedbacksState?.length
    ) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [projectsTasksState]);

  useEffect(() => {
    !!selectedFeedback
      ? setShowPreviewFeedbackModal(true)
      : setShowPreviewFeedbackModal(false);
  }, [selectedFeedback]);

  const data = {
    labels: onlyPositiveCountStatuses?.map((status) => status?.label),
    datasets: [
      {
        label: "# of Votes",
        data: onlyPositiveTasksCount?.map(
          (count) => projectsTasksMetaState?.totalPerStatus[count]
        ),

        backgroundColor: onlyPositiveCountStatuses?.map(
          (status) => statusColors[status.color]
        ),
        borderColor: ["rgba(255, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
      },
    },
  };

  const handleFilterChange = (e) => {
    const status = parseInt(e.target.value);
    setSelectedFilter(status);
    doFetchProjectsTasks({ status });
  };

  const handleResetFilters = () => {
    setSelectedFilter(null);
    doFetchProjectsTasks({});
  };

  const handleFeedbackInfoClick = (feedback) => {
    setSelectedFeedback(feedback);
  };

  const handleCloseModal = () => {
    setSelectedFeedback(null);
    setShowGiveFeedbackModal(false);
    setShowRequestFeedbackModal(false);
  };

  const handleOpenModal = (type) => {
    if (type === "give") {
      setShowGiveFeedbackModal(true);
      return;
    }
    setShowRequestFeedbackModal(true);
  };

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        {/* <div className="single-view-section">
          <h2>Feedbacks</h2>
        </div>
        <div className="single-view-section">
          <h2>List of previous feedbacks</h2>
        </div> */}
        <div className="single-view-section feedbacks">
          <div className="single-view-header-filters">
            <h2>Feedbacks</h2>
          </div>
          {!feedbacksState ? (
            <div className="no-feedbacks">
              <h2>There is no feedback to view</h2>
              <p>
                Get started by{" "}
                <span>
                  <Button
                    variant="link"
                    buttonStyles={{
                      display: "inline-flex",
                      padding: "0",
                      fontSize: "16px",
                    }}
                    handleClick={() => handleOpenModal("give")}
                  >
                    giving feedback
                  </Button>
                </span>{" "}
                or{" "}
                <span>
                  <Button
                    variant="link"
                    buttonStyles={{
                      display: "inline-flex",
                      padding: "0",
                      fontSize: "16px",
                    }}
                    handleClick={() => handleOpenModal("request")}
                  >
                    requesting feedback
                  </Button>
                </span>{" "}
                from your peers.
              </p>
            </div>
          ) : (
            <div className="feedbacks-list">
              <h2>List of previous feedbacks</h2>
              <ul>
                <li>
                  <p className="column list-header">Feedback sender</p>
                  <p className="column list-header">Feedback date</p>
                  <div className="column list-header"></div>
                </li>
                {feedbacksState?.map((feedback) => (
                  <li key={`feedback-${feedback?.id}`}>
                    <div className="column">
                      <p className="sender">{`From: ${feedback?.sender?.name}`}</p>
                      <p>{feedback?.question}</p>
                    </div>
                    <p className="column">{feedback?.createdAt}</p>
                    <div className="column feedback-info">
                      <div className="info">
                        <p className="title">Feedback info:</p>
                        <p>{feedback?.answer}</p>
                      </div>
                      <div className="action">
                        <span className="vertical-line"></span>
                        <Button
                          variant="control"
                          handleClick={() => handleFeedbackInfoClick(feedback)}
                        >
                          <SingleArrow />
                        </Button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <hr />
        <div className="single-view-section">
          <div className="single-view-header-filters">
            <h2>Tasks</h2>
            <div className="single-view-filters">
              <Filter
                filters={performanceTaskStatuses}
                checked={selectedFilter}
                onRadioChange={handleFilterChange}
                onReset={handleResetFilters}
              />
            </div>
          </div>
          <div className="single-view-content-flex">
            <div className="single-view-half">
              <ul className="main-list">
                <li className="main-list-labels">
                  <span>Task list</span>
                  <span>Progress</span>
                  <span>Deadline</span>
                </li>
                {projectsTasksState?.map((task) => (
                  <li key={`project-task-${task?.id}`}>
                    <div className="main-list-item">
                      <p className="bold text-xs">{task?.title}</p>
                      <span className="text-xs">{task?.description}</span>
                    </div>
                    <div className="main-list-item status-item">
                      <Status
                        status={performanceTaskStatuses?.find(
                          (status) => status?.value === task?.status
                        )}
                      />
                    </div>
                    <p className="main-list-item">{task?.settings?.endDate}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="single-view-half flex gap-20 items-center">
              <div className="single-view-statuses">
                {performanceTaskStatuses?.map((status) => (
                  <CardFilter
                    title={status?.label}
                    variant={status?.color}
                    icon={status?.icon}
                    count={
                      projectsTasksMetaState?.totalPerStatus[status?.value]
                    }
                    key={`status-${status?.id}}`}
                  />
                ))}
              </div>
              <div className="pie-wrapper">
                <Pie data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewFeedbackForm
        showPreviewFeedbackModal={showPreviewFeedbackModal}
        handleModalClose={handleCloseModal}
        selectedFeedback={selectedFeedback}
      />
      <GiveFeedbackForm
        showGiveFeedbackModal={showGiveFeedbackModal}
        handleModalClose={handleCloseModal}
      />
      <RequestFeedbackForm
        showRequestFeedbackModal={showRequestFeedbackModal}
        handleModalClose={handleCloseModal}
      />
    </div>
  );
};

export default FeedbacksAndTasks;
