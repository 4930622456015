import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const createMood = createAsyncThunk(
  "checkins/mood/create",
  async (mood) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/checkin/mood-check`,
        mood
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchQuestions = createAsyncThunk(
  "checkins/questions/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/questions`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAnswers = createAsyncThunk(
  "checkins/answers/create",
  async (answer) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/checkin/answer`,
        answer
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCheckinsHistory = createAsyncThunk(
  "checkins/history/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/history`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserCheckinsHistory = createAsyncThunk(
  "checkins/userHistory/fetch",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/history`);
      const data = response.data;

      const filteredDataArray = Object.keys(data)
        .reduce((acc, date) => {
          const userData = data[date];
          const filteredQuestions = (userData?.questions || []).filter(
            (question) => question?.user_id === userId
          );
          const filteredGrades = (userData?.grades || []).filter(
            (grades) => grades?.user_id === userId
          );

          if (filteredQuestions.length > 0 || filteredGrades.length > 0) {
            acc.push({
              date,
              questions: filteredQuestions,
              grades: filteredGrades,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          const dateA = a.date.split(".").reverse().join("-");
          const dateB = b.date.split(".").reverse().join("-");

          return dateB.localeCompare(dateA);
        });

      return filteredDataArray;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserMoodStatistics = createAsyncThunk(
  "checkins/user/statistics",
  async ({ user, range }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          user,
          range,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
