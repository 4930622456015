export const dummyTimeline = [
  {
    date: "05.03.2024",
    title: "3 months",
    description: "1st performance review",
    status: 2,
  },
  {
    date: "05.06.2024",
    title: "6 months",
    description: "2nd performance review",
    status: 2,
  },
  {
    date: "05.09.2024",
    title: "9 months",
    description: "3rd performance review",
    status: 4,
  },
  {
    date: "05.12.2024",
    title: "12 months",
    description: "End of the year",
    status: 3,
  },
];

export const dummyReviewTimelineList = [
  "Upcoming performance review Dec. 2024",
  "Overdue performance review Sep. 2024",
  "Completed performance review Jun. 2024",
  "Completed performance review Mar. 2024",
  "Completed performance review Dec. 2023",
  "Completed performance review Sep. 2023",
  "Completed performance review Jun. 2023",
  "Completed performance review Mar. 2023",
];

export const dummyTimeOnPosition = [
  "Junior Designer 01.21.2020",
  "Medior Designer 01.21.2021",
  "Senior Designer 01.21.2022",
  "Designer Director 01.21.2024",
];

export const dummyReviewList = [
  {
    id: 1,
    sender: "Bojan Sekulic",
    review_title: "Annual Performance Evaluation",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
  {
    id: 2,
    sender: "Bojan Sekulic",
    review_title: "Quarterly Progress Review",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
  {
    id: 3,
    sender: "Bojan Sekulic",
    review_title: "Mid-Year Performance Check",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
  {
    id: 4,
    sender: "Bojan Sekulic",
    review_title: "360-Degree Feedback Session",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
  {
    id: 5,
    sender: "Bojan Sekulic",
    review_title: "Goal Achievement Assessment",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
  {
    id: 6,
    sender: "Bojan Sekulic",
    review_title: "Professional Development Review",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        checked: false,
      },
      {
        responsibility: "Clean the office",
        checked: true,
      },
      {
        responsibility: "Do the laundry",
        checked: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        checked: false,
      },
      {
        behavior: "Be professional",
        checked: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        checked: false,
      },
      {
        interest: "Help others",
        checked: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        checked: false,
      },
      {
        skill: "Problem Solving",
        checked: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
  },
];
export const dummyPreviousReviews = [
  {
    id: 1,
    sender: "Bojan Sekulic",
    review_title: "Annual Performance Evaluation",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 2,
  },
  {
    id: 2,
    sender: "Bojan Sekulic",
    review_title: "Quarterly Progress Review",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 1,
  },
  {
    id: 3,
    sender: "Bojan Sekulic",
    review_title: "Mid-Year Performance Check",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 3,
  },
  {
    id: 4,
    sender: "Bojan Sekulic",
    review_title: "360-Degree Feedback Session",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 2,
  },
  {
    id: 5,
    sender: "Bojan Sekulic",
    review_title: "Goal Achievement Assessment",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 1,
  },
  {
    id: 6,
    sender: "Bojan Sekulic",
    review_title: "Professional Development Review",
    date: "05.10.2024",
    responsibilities: [
      {
        responsibility: "Finish the project on time",
        accomplished: false,
      },
      {
        responsibility: "Clean the office",
        accomplished: true,
      },
      {
        responsibility: "Do the laundry",
        accomplished: false,
      },
    ],
    behaviors: [
      {
        behavior: "Be on time",
        accomplished: false,
      },
      {
        behavior: "Be professional",
        accomplished: true,
      },
    ],
    interests: [
      {
        interest: "Learn new things",
        accomplished: false,
      },
      {
        interest: "Help others",
        accomplished: true,
      },
    ],
    skills: [
      {
        skill: "Communication",
        accomplished: false,
      },
      {
        skill: "Problem Solving",
        accomplished: true,
      },
    ],
    company_culture:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cupiditate dolor quo. Pariatur omnis minus, optio dolores at quidem debitis.",
    growth_opportunities:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur qui a eveniet ex officiis voluptatem fugiat. Dignissimos iste quisquam ipsum, tempore consectetur laborum voluptatem blanditiis porro vel dicta qui quaerat.",
    compensation_information:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque natus aut laboriosam corporis aliquam adipisci fuga sed saepe commodi ea, perferendis, numquam necessitatibus tempore accusamus quae labore molestiae odit eaque dolore veniam voluptatum voluptas quas obcaecati. Labore, repellendus illum. Nostrum cumque repellat perspiciatis vitae est consequatur iure amet maiores aperiam?",
    conclusion: 3,
  },
];
