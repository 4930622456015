import { Routes, Route } from "react-router-dom";

import PersonalPerformance from "../components/PersonalPerformance";
import Dashboard from "../components/PersonalPerformance/dashboard";

const PersonalPerformanceRoutes = () => (
  <Routes>
    <Route path="/" element={<PersonalPerformance />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/form" element={<Dashboard />} />
    <Route path="/drafts" element={<Dashboard />} />
  </Routes>
);

export default PersonalPerformanceRoutes;
