import { GreenCircleArrowIcon, RedCircleArrowIcon } from "../../../icons";
import "./styles.scss";

const TrendCard = ({ label, count, percentage }) => {
  return (
    <div className="trend-filter">
      <p>{label}</p>
      <div className="trend-filter-bottom">
        <div
          className={`percentage-icon ${percentage < 0 ? "red-border" : ""}`}
        >
          {percentage >= 0 ? <GreenCircleArrowIcon /> : <RedCircleArrowIcon />}
          <span>{percentage}%</span>
        </div>
        <p className="trend-count">{count}</p>
      </div>
    </div>
  );
};

export default TrendCard;
