import { useEffect } from "react";
import { PencilIcon, DeleteIcon } from "../../../icons";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormType } from "../../../store/form/formSlice";

import {
  deleteTemplate,
  fetchSingleTemplate,
  fetchDrafts,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";

const DashboardView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draftsState = useSelector((state) => state.performance)?.drafts;
  const [doFetchDrafts] = useThunk(fetchDrafts);
  const [doDeleteTemplate] = useThunk(deleteTemplate);
  const [doFetchSingleTemplate] = useThunk(fetchSingleTemplate);

  useEffect(() => {
    doFetchDrafts();
  }, []);

  const handleEdit = (templateId) => {
    doFetchSingleTemplate(templateId);
    dispatch(setFormType("edit"));
    navigate("/personal-performance/form");
  };

  const handleDelete = (templateId) => {
    doDeleteTemplate(templateId);
  };

  return (
    <div className="dashboard-flex-item w-60">
      <div className="dashboard-block">
        <div className="dashboard-templates">
          <div className="dashboard-templates-content">
            <h3>Drafts</h3>
            <div className="dashboard-templates-wrapper">
              {draftsState?.map((template) => (
                <div
                  className="dashboard-template"
                  key={`template-${template?.id}`}
                >
                  <div className="dashboard-template-content">
                    <p>
                      <strong>{template?.title}</strong>
                    </p>
                    <p>{template?.created_at}</p>
                    <div className="dashboard-template-actions">
                      <div
                        className="template-action"
                        onClick={() => handleEdit(template?.id)}
                      >
                        <PencilIcon />
                      </div>
                      <div
                        className="template-action"
                        onClick={() => handleDelete(template?.id)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
